
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import _ from "lodash";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Modal } from "bootstrap";
import * as Yup from "yup";
import ElCurrency from "@/components/financial/CurrencyInput.vue";

export default defineComponent({
  name: "MyKNPPreAssessmentForm",
  props: ["cstmr_id"],
  components: {
    Form,
    Field,
    ElCurrency,
    ErrorMessage,
  },
  data() {
    const store = useStore();
    const myKNPPreAssessementFormReady = false;

    // const schemaMyKNPPreAssessementForm = Yup.object({
    //   self_employed: Yup.object({
    //     income_statement: Yup.object({
    //       average_income: Yup.number(),
    //       rental_income: Yup.number(),
    //       business_income: Yup.number(),
    //     }),
    //     checklist: Yup.object({
    //       average_income: Yup.object({
    //         // bank_statement: Yup.mixed().when("average_income", {
    //         //   is: (average_income) => average_income > 0,
    //         //   then: Yup.boolean()
    //         //     // .test(
    //         //     //   "",
    //         //     //   "You have to agree with the PDPA to continue",
    //         //     //   (value) => value === true
    //         //     // )
    //         //     .required("You have to agree with the PDPA to continue.")
    //         //     .label("PDPA"),
    //         //   otherwise: Yup.boolean().notRequired(),
    //         // }),
    //       }),
    //     }),
    //   }),
    //   // average_income_bank_statement: Yup.boolean()
    //   //   .test(
    //   //     "",
    //   //     "You have to agree with the PDPA to continue",
    //   //     (value) => value === true
    //   //   )
    //   //   .required("You have to agree with the PDPA to continue.")
    //   //   .label("PDPA"),
    // });

    // employed and self employed

    const employed = {
      income: {
        gross_salary: 0,
        fixed_allowance: 0,
      },
      total_income: 0,
      deduction: {
        epf: 0,
        tax_zakat: 0,
        socso_eis: 0,
      },
      total_deduction: 0,
      net_income: 0,
    };

    const self_employed = {
      income_statement: {
        average_income: 0,
        rental_income: 0,
        business_income: 0,
        total_income: 0,
        income_considered_by_bank: 0,
      },
      checklist: {
        average_income: {
          bank_statement: "false",
        },
        rental_income: {
          bank_statement: "false",
          tenancy_agreement: "false",
        },
        business_income: {
          profit_and_loss_account: "false",
          balance_sheet: "false",
          director_fee_slip: "false",
        },
      },
    };

    const income_considered_by_bank = 0;

    const loan_repayment = {
      housing_loan_financing: 0,
      hire_purchase: 0,
      credit_card: 0,
      personal_loan_financing: 0,
      other_loan: 0,
      total: 0,
    };

    const dsr = {
      current_loan_repayment: 0,
      future_housing_loan_repayment: 0,
      total_loan_repayment: 0,
      percentage: 0,
    };

    const charater = {
      ccris: {
        arrears: false,
        special_attention: false,
      },
      ctos: {
        legal_action: false,
        payment_pattern: false,
        utility: false,
        singer: false,
        courts_mammoth: false,
        bike_shop: false,
        telco: false,
        other: false,
      },
      remark: "",
    };

    const capital = {
      deposit_required: {
        check: false,
        amount: 0,
      },
      first_second_house: {
        check: false,
        amount: 0,
      },
      third_more_house: {
        check: false,
        amount: 0,
      },
      legal_fee: {
        check: false,
        amount: 0,
      },
      stamp_duties: {
        check: false,
        amount: 0,
      },
      valuation_fees: {
        check: false,
        amount: 0,
      },
      renovation_fees: {
        check: false,
        amount: 0,
      },
      remark: "",
    };

    const collateral = {
      housing_loan: "",
      leasehold_remaining_years: 0,
      developer: "",
      property_location: "",
      resident: "",
      remark: "",
    };

    const condition = {
      purpose: "",
      loan_type: "",
      rate: "",
      principal_involved: "",
      employment_in_which_industry: "",
      remark: "",
    };

    return {
      store,
      myKNPPreAssessementFormReady,
      // schemaMyKNPPreAssessementForm,
      income_considered_by_bank,
      employed,
      self_employed,
      loan_repayment,
      dsr,
      average_income: self_employed.income_statement.average_income,
      charater,
      capital,
      collateral,
      condition,
    };
  },
  methods: {
    init() {
      this.store
        .dispatch(Actions.GET_USER_RAW, {
          user_id: this.cstmr_id,
          type: "myknp_pre_assessment",
        })
        .then(() => {
          const data = this.store.getters.getMyKNPPreAssessmentData;
          console.log(data);
          if (data.employed) {
            this.employed = data.employed;
            this.self_employed = data.self_employed;
            this.loan_repayment = data.loan_repayment;
            this.dsr = data.dsr;
            this.income_considered_by_bank = data.income_considered_by_bank;
            this.charater = data.charater ? data.charater : this.charater;
            this.capital = data.capital ? data.capital : this.capital;
            this.collateral = data.collateral
              ? data.collateral
              : this.collateral;
            this.condition = data.condition ? data.condition : this.condition;
            this.myKNPPreAssessementFormReady = true;
            this.onUpdatePreAssessmentInfo();
          } else {
            this.myKNPPreAssessementFormReady = true;
          }
        });
    },
    onSubmitMyKNPPreAssessementForm() {
      Swal.fire({
        text: "Are you sure to save the Pre Assessment Info?",
        icon: "info",
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            user_id: this.cstmr_id,
            type: "myknp_pre_assessment",
            raw: {
              employed: this.employed,
              self_employed: this.self_employed,
              income_considered_by_bank: this.income_considered_by_bank,
              loan_repayment: this.loan_repayment,
              dsr: this.dsr,
              charater: this.charater,
              capital: this.capital,
              collateral: this.collateral,
              condition: this.condition,
            },
          };
          this.store.dispatch(Actions.STORE_RAW, data).then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "MyKNP Pre Assessment Info saved!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            });
            this.onUpdatePreAssessmentInfo();
          });
        }
      });
    },

    onUpdatePreAssessmentInfo() {
      this.$emit("updateSavedInfo", "preAssessment", true);
    },

    onCalculateIncome() {
      this.employed.total_income =
        this.employed.income.gross_salary +
        this.employed.income.fixed_allowance;
      this.employed.total_deduction =
        this.employed.deduction.epf +
        this.employed.deduction.tax_zakat +
        this.employed.deduction.socso_eis;
      this.employed.net_income =
        this.employed.total_income - this.employed.total_deduction;
      this.onCalculateIncomeCosideredByBank();
    },
    onCalculateIncomeSelfEmployed() {
      this.self_employed.income_statement.total_income =
        this.self_employed.income_statement.average_income +
        this.self_employed.income_statement.rental_income +
        this.self_employed.income_statement.business_income;
      this.onCalculateIncomeCosideredByBank();
    },
    onCalculateLoan() {
      this.loan_repayment.total =
        this.loan_repayment.housing_loan_financing +
        this.loan_repayment.hire_purchase +
        this.loan_repayment.credit_card +
        this.loan_repayment.personal_loan_financing +
        this.loan_repayment.other_loan;
      this.onCalculateDSR();
    },
    onCalculateIncomeCosideredByBank() {
      this.self_employed.income_statement.income_considered_by_bank =
        0.7 * this.self_employed.income_statement.total_income;
      this.income_considered_by_bank =
        this.employed.net_income +
        this.self_employed.income_statement.income_considered_by_bank;

      this.onCalculateDSR();
    },
    onCalculateDSR() {
      this.dsr.current_loan_repayment = this.loan_repayment.total;
      this.dsr.total_loan_repayment =
        this.dsr.current_loan_repayment +
        this.dsr.future_housing_loan_repayment;
      // this.dsr.percentage =
      //   (this.dsr.total_loan_repayment /
      //     (this.employed.net_income +
      //       this.self_employed.income_statement.total_income)) *
      //   100;

      this.dsr.percentage =
        (this.dsr.total_loan_repayment / this.income_considered_by_bank) * 100;
    },
    roundToTwoDecimalPlaces(number: number) {
      if (isNaN(number)) {
        return 0;
      }
      const numberString = number.toFixed(2);
      return numberString;
    },
    onHousingLoanChange(e) {
      console.log(e);
    },
  },
  async mounted() {
    this.init();
  },
});
