
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Modal } from "bootstrap";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "invite-friends-modal",
  components: { Form, Field, ErrorMessage },
  props: ["customer", "oqfReason", "oqfData", "showInOqfForm"],
  setup() {
    const oqfForm = ref(null);
    const closeModal = () => Modal.getInstance(oqfForm.value)?.hide();
    const openModal = () => Modal.getInstance(oqfForm.value)?.show();
    const onlineQueryFormValidator = Yup.object({
      oqf: Yup.object({
        branchId: Yup.string().required().label("Branch"),
        serviceTypeId: Yup.string().required().label("Service"),
        remarks: Yup.string().required().label("Remarks"),
      }),
    });
    const typeOfIds = [
      {
        value: "MyKad",
        label: "MyKad",
      },
      {
        value: "Passport",
        label: "Passport",
      },
    ];

    const branches = [
      { value: "0", label: "Alor Star Branch" },
      { value: "1", label: "Ipoh  Branch" },
      { value: "2", label: "Johor Bahru Branch" },
      { value: "3", label: "KL Main Branch" },
      { value: "4", label: "Kota Bharu Branch" },
      { value: "5", label: "Kota Kinabalu Branch" },
      { value: "6", label: "Kuala Terengganu Branch" },
      { value: "7", label: "Kuantan Branch" },
      { value: "8", label: "Kuching Branch" },
      { value: "9", label: "Melaka Branch" },
      { value: "10", label: "Penang Branch" },
    ];

    const services = [
      { value: "0", label: "PPK : Penyertaan Semula Kali ke 2" },
      { value: "1", label: "PPK: Penarikan diri (< 1 Tahun)" },
      { value: "2", label: "PPK: Permohonan Penjadualan Semula" },
      { value: "3", label: "PPK:Permohonan Penangguhan Bayaran" },
      // { value: "4", label: "URUS/FIRST: Permohonan Penambahan Fasiliti" },
      // {
      //   value: "5",
      //   label:
      //     "URUS:Permohonan Penangguhan Bantuan Banjir(Penangguhan Bayaran)",
      // },
      // { value: "6", label: "URUS:Permohonan Penangguhan bayaran" },
    ];

    return {
      onlineQueryFormValidator,
      typeOfIds,
      branches,
      services,
      oqfForm,
      openModal,
      closeModal,
    };
  },
  data() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const showOqfForm = false;
    const oqf = {
      customerName: this.customer.name,
      typeOfId: "MyKad",
      customerId: this.customer.nric,
      contactNumber: this.customer.phone,
      email: this.customer.email,
      branchId: "",
      serviceTypeId: "",
      remarks: "",
      reason: this.oqfReason,
      fileName: "",
    };

    const oqfs = [];
    const lastSubmittedOqf = "";

    return {
      oqf,
      route,
      router,
      store,
      oqfs,
      showOqfForm,
      lastSubmittedOqf,
    };
  },
  methods: {
    onlineQueryFormSubmit() {
      const data = {
        customer_name: this.customer.name,
        type_of_id: "MyKad",
        customer_id: this.customer.nric,
        contact_number: this.customer.phone,
        email: this.customer.email,
        organisation_id: this.oqf.branchId.toString(),
        services: this.oqf.serviceTypeId.toString(),
        remarks: this.oqf.remarks.toString(),
        reason: this.oqfReason,
        user_id: this.customer.id,
        created_by: this.store.getters.currentUser.id,
      };

      // this.showOqfForm = false;
      // this.lastSubmittedOqf = this.getHumanDate(moment());

      // this.closeModal();

      // this.$emit("updateShowOqfForm", false);

      // this.$router.go(0);

      Swal.fire({
        title: "Submit the Online Query Form!",
        text: "Are you sure want to submit the Form?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, I am not!",
        confirmButtonText: "OK",
      }).then((value) => {
        if (value.isConfirmed) {
          Swal.fire({
            title: "Submitting",
            text: "Please wait while we submit your query.",
            icon: "warning",
            showCancelButton: false,
            showConfirmButton: false,
            closeOnClickOutside: false,
          });
          this.store.dispatch(Actions.STORE_OQF, data).then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "Online Query Form has been submitted!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(() => {
              this.$router.go(0);
              // this.showOqfForm = false;
              // emit("updateShowOqfForm", false);
              // this.$emit("updateShowOqfForm", false);
            });
          });
        } else {
          this.oqf.branchId = "";
          this.oqf.serviceTypeId = "";
          this.oqf.remarks = "";
        }

        // this.$emit("updateShowOqfForm", false);
      });
    },
    init() {
      this.store.dispatch(Actions.GET_OQFS, this.customer.id).then(() => {
        this.oqfs = this.store.getters.getOqfsData;

        if (this.oqfs && this.oqfs.length > 0) {
          let lastSubmittedDate = this.oqfs[0].created_at;
          this.lastSubmittedOqf = this.getHumanDate(lastSubmittedDate);
          let today = moment();
          var diff = moment(today).diff(lastSubmittedDate, "days");
          if (diff >= 5) {
            this.showOqfForm = true;
          }
        } else {
          this.showOqfForm = true;
        }
      });
    },
    getHumanDate(date) {
      return moment(date).format("DD/MM/YYYY h:mm:ss a");
    },
  },
  async mounted() {
    this.init();
  },
});
