
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import _ from "lodash";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Modal } from "bootstrap";
import * as Yup from "yup";

export default defineComponent({
  name: "Documents",
  props: ["cstmr_id"],
  components: {
    // ErrorMessage,
    Field,
    Form,
  },
  data() {
    const media = [];
    const user_id = this.cstmr_id;
    return {
      myknpFDS: null,
      documents: new FormData(),
      store: useStore(),
      media,
      user_id,
    };
  },
  methods: {
    init() {
      this.getDocuments();
    },
    // },
    onUpdatePropertyInfo() {
      this.$emit("updateSavedInfo", "property", true);
    },
    onDocumentSubmit(value) {
      for (const key in value) {
        if (value[key] instanceof File) {
          this.documents.append(key, value[key]);
        } else if (typeof value[key] !== "undefined") {
          this.documents.append(key, value[key]);
        }
      }

      this.store
        .dispatch(Actions.STORE_DOC, this.documents)
        .then((response) => {
          this.getDocuments();
        });
    },
    getDocuments() {
      this.store
        .dispatch(Actions.GET_DOC_BY_USER_TAG, {
          user_id: this.cstmr_id,
          tag: "myknpFDS",
        })
        .then((response) => {
          this.media = this.store.getters.getMediaData.data;
          if (this.media.length > 0) {
            this.$emit("updateSavedInfo", "documents", true);
          }
        });
    },
  },
  async mounted() {
    this.init();
  },
});
