
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import _ from "lodash";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Modal } from "bootstrap";
import * as Yup from "yup";

import ElCurrency from "@/components/financial/CurrencyInput.vue";
import ChecklistItem from "./CheclistItem.vue";
import JointCustomerInfo from "./JointCustomerInfo.vue";

export default defineComponent({
  name: "MyKNPChecklist",
  props: ["cstmr_id"],
  components: {
    Form,
    Field,
    ChecklistItem,
    ElCurrency,
    JointCustomerInfo,
    ErrorMessage,
  },
  setup() {
    const checklist_1 = ref(null);
    const checklist_2 = ref(null);
    const checklist_3 = ref(null);
    const checklist_4 = ref(null);
    const checklist_5 = ref(null);
    const checklist_6 = ref(null);
    const checklist_7 = ref(null);
    const checklist_8 = ref(null);
    const checklist_9 = ref(null);
    const checklist_10 = ref(null);

    const callChildFunction = (v) => {
      checklist_1.value.updateCheck(v);
      checklist_2.value.updateCheck(v);
      checklist_3.value.updateCheck(v);
      checklist_4.value.updateCheck(v);
      checklist_5.value.updateCheck(v);
      checklist_6.value.updateCheck(v);
      checklist_7.value.updateCheck(v);
      checklist_8.value.updateCheck(v);
      checklist_9.value.updateCheck(v);
      checklist_10.value.updateCheck(v);
    };

    return {
      checklist_1,
      checklist_2,
      checklist_3,
      checklist_4,
      checklist_5,
      checklist_6,
      checklist_7,
      checklist_8,
      checklist_9,
      checklist_10,
      callChildFunction,
    };
  },
  data() {
    const store = useStore();

    const customerBasicInfo = {
      name: "",
      nric: "",
      phone: "",
      email: "",
    };

    const propertyInfo = {
      customer_name: "",
      customer_nric: "",
      customer_phone: "",
      customer_email: "",
      has_join_customer: "Yes",
      join_customers: [],
      location: "",
      value: "",
    };

    const schemaPropertyInfo = Yup.object({
      propertyInfo: Yup.object({
        has_join_customer: Yup.string().required().label("Has Join Customer"),
        location: Yup.string().required().label("Address"),
        // value: Yup.string().required().label("Value of Property"),
      }),
    });

    const dataSetChecklist = {
      checklist_1: false,
      checklist_2: false,
      checklist_3: false,
      checklist_4: false,
      checklist_5: false,
      checklist_6: false,
      checklist_7: false,
      checklist_8: false,
      checklist_9: false,
      checklist_10: false,
    };

    const checkAll = false;
    const checklistSubmitted = false;
    const propertyInfoSubmitted = false;

    return {
      store,
      customerBasicInfo,
      propertyInfo,
      schemaPropertyInfo,
      checkAll,
      dataSetChecklist,
      checklistSubmitted,
      isChecklistready: false,
      propertyInfoSubmitted,
      myKNPReady: false,
    };
  },
  methods: {
    init() {
      this.store
        .dispatch(Actions.GET_USER_BASIC_PROFILE, this.cstmr_id)
        .then(() => {
          this.customerBasicInfo = this.store.getters.getCustomerBasicProfileData;
          this.propertyInfo.customer_name = this.customerBasicInfo.name;
          this.propertyInfo.customer_nric = this.customerBasicInfo.nric;
          this.propertyInfo.customer_phone = this.customerBasicInfo.phone;
          this.propertyInfo.customer_email = this.customerBasicInfo.email;
          this.store
            .dispatch(Actions.GET_USER_RAW, {
              user_id: this.cstmr_id,
              type: "myknp_checklist",
            })
            .then(() => {
              const data = this.store.getters.getMyKNPChecklistData;
              if (data.checkAll) {
                this.dataSetChecklist = data.dataSetChecklist;
                this.checkAll = data.checkAll;
                this.checklistSubmitted = true;
                this.onUpdateChecklistInfo();
              }

              this.store
                .dispatch(Actions.GET_USER_RAW, {
                  user_id: this.cstmr_id,
                  type: "myknp_property_info",
                })
                .then(() => {
                  const data = this.store.getters.getMyKNPPropertyInfoData;
                  if (Object.keys(data).length !== 0) {
                    this.propertyInfo = data;
                    this.propertyInfoSubmitted = true;
                    this.onUpdatePropertyInfo();
                  }
                  this.myKNPReady = true;
                });
            });
        });
    },
    updateJoinCustomers(value) {
      this.propertyInfo.join_customers = value;
    },
    onSubmitPropertyInfo() {
      if (
        this.propertyInfo.has_join_customer === "Yes" &&
        this.propertyInfo.join_customers.length === 0
      ) {
        Swal.fire({
          text: "Please add joint holder",
          icon: "warning",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });
      } else {
        Swal.fire({
          text: "Are you sure to submit the Property Info?",
          icon: "info",
          showConfirmButton: true,
          showCancelButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            const data = {
              user_id: this.cstmr_id,
              type: "myknp_property_info",
              raw: this.propertyInfo,
            };
            this.store
              .dispatch(Actions.STORE_RAW, data)
              .then(() => {
                Swal.fire({
                  title: "Congratulation!",
                  text: "MyKNP Property Info submitted!",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                });
              })
              .then(() => {
                this.propertyInfoSubmitted = true;
                this.onUpdatePropertyInfo();
              });
          }
        });
      }
    },
    onCheckAll(e) {
      this.checkAll = e.target.checked;
      this.callChildFunction(e.target.checked);
    },

    onCheck(value, path) {
      _.set(this.dataSetChecklist, path, value);

      this.checkAll = true;

      Object.keys(this.dataSetChecklist).forEach((key) => {
        if (!this.dataSetChecklist[key]) {
          this.checkAll = false;
        }
      });
    },
    updateChecklist() {
      for (const key in this.dataSetChecklist) {
        this.dataSetChecklist[key] = true;
      }

      const data = {
        checkAll: true,
        dataSetChecklist: this.dataSetChecklist,
      };

      return data;
    },
    onSubmit() {
      if (this.propertyInfoSubmitted === false) {
        Swal.fire({
          text:
            "Please complete the Property Info and click 'Save Property Info' button.",
          icon: "warning",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });
        return;
      } else if (this.checkAll) {
        Swal.fire({
          text:
            "Are you sure to submit the checklist? Once submitted, you cannot update the checklist.",
          icon: "info",
          showConfirmButton: true,
          showCancelButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            const data = {
              user_id: this.cstmr_id,
              type: "myknp_checklist",
              raw: this.updateChecklist(),
            };
            this.store
              .dispatch(Actions.STORE_RAW, data)
              .then(() => {
                Swal.fire({
                  title: "Congratulation!",
                  text: "MyKNP Checklist submitted!",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                });
              })
              .then(() => {
                this.checklistSubmitted = true;
                this.onUpdateChecklistInfo();
                // this.$refs.cancelModalButon.click();
              });
          }
        });
      } else {
        Swal.fire({
          text: "Please complete the checklist",
          icon: "warning",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });
      }
    },
    onUpdateChecklistInfo() {
      this.$emit("updateSavedInfo", "checklist", true);
    },
    onUpdatePropertyInfo() {
      this.$emit("updateSavedInfo", "property", true);
    },
  },
  async mounted() {
    this.init();
  },
});
