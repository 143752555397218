
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";

export default defineComponent({
  name: "invite-friends-modal",
  components: {
    Field,
  },
  setup() {
    return {};
  },
  data() {
    const remarks = "";

    return {
      remarks,
    };
  },
  methods: {
    onSubmit() {
      this.$emit("withdrawPFP", this.remarks);
    },
  },
});
