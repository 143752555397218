
import { defineComponent } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import SocialSynergy from "@/components/modals/SocialSynergy.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import OQF from "@/components/modals/OQF.vue";
import WithdrawPFP from "@/components/modals/WithdrawPFP.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import jQuery from "jquery";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import PreMyKNP from "./myknp/PreMyKNP.vue";
import PostMyKNP from "./myknp/PostMyKNP.vue";
import ReverseMortgage from "./ReverseMortgage.vue";

export default defineComponent({
  name: "profile",
  components: {
    Field,
    SocialSynergy,
    WithdrawPFP,
    OQF,
    PreMyKNP,
    PostMyKNP,
    ReverseMortgage,
  },
  data() {
    let isLoading = true;
    const isModalVisible = false;
    const route = useRoute();
    const router = useRouter();
    const cstmr_id = route.params.id ?? null;
    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {
        employment_status: "",
        pfp_profiling: "",
        dmp_profiling: "",
        city: "",
        state: "",
      },
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
      net_worth_secured_liabilities: [],
      net_worth_secured_total: [],
      net_worth_unsecured_liabilities: [],
      net_worth_unsecured_total: [],
    };
    const customerPFP = {
      id: null,
      user_id: cstmr_id,
      type: "PFP",
      raw: {
        profile: "",
        is_join_pfp: "",
        remarks: "",
      },
    };
    const oqfReason = "";
    const oqfWithdrawalDMPReason = "";
    const oqfWithdrawalDMPReasons = {
      WithdrawalDMP_highLoanCommitment: "High Loan Commitment",
      WithdrawalDMP_holisticLoanRestructurint: "Holistic Loan restructuring",
      WithdrawalDMP_nonPerformingLoan: "Non-Performing Loan",
    };

    const oqfWithdrawalFIReason = "";
    const oqfWithdrawalFIReasons = {
      withdrawalFI_theFinancialSituationHasImproved:
        "The financial situation has improved",
      withdrawalFI_fisSSpecialRepaymentPackage:
        "FIS's Special Repayment Package",
      withdrawalFI_removeCcrisTagging: "Remove CCRIS Tagging",
      withdrawalFI_applyForANewLoanApplication:
        "Apply for a new loan application",
    };

    const nFrmtThsnd = (data) => {
      if (!isNaN(data)) {
        return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else if (data) {
        return data.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return "0";
      }
    };

    const showOqfForm = false;
    const showInOqfForm = false;
    const oqfs = [];
    const lastSubmittedOqf = "";
    const showGeneratePFPReportBttn = false;
    const pfpReportGenerated = false;

    const store = useStore();
    const roles = store.getters?.currentUser?.roles?.map((a) => a.name);

    const isAdmin = roles?.includes("admin");
    const isFA = roles?.includes("financial-advisor");
    const isViewer = roles?.includes("viewer");
    const isBranchHead = roles?.includes("branch-head");

    return {
      route,
      router,
      store: useStore(),
      cstmr_id,
      customer,
      customerPFP,
      isLoading,
      nFrmtThsnd,
      isModalVisible,
      oqfs,
      showOqfForm,
      showInOqfForm,
      oqfReason,
      oqfWithdrawalDMPReason,
      oqfWithdrawalDMPReasons,
      oqfWithdrawalFIReason,
      oqfWithdrawalFIReasons,
      lastSubmittedOqf,
      showGeneratePFPReportBttn,
      pfpReportGenerated,
      isAdmin,
      isFA,
      isViewer,
      isBranchHead,
      isViewDetails: true,
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
        let customer = this.store.getters.getCustomerData;
        this.customer = customer;
        let userRawProfile = customer.user_raw_profile?.raw;

        if (!customer.detail) {
          customer.detail = {
            employment_status: "",
            pfp_profiling: "",
            dmp_profiling: "",
            city: "",
            state: "",
          };
        }

        this.customer.name = userRawProfile
          ? userRawProfile.name
          : customer.name;
        this.customer.nric = userRawProfile
          ? userRawProfile.nric
          : customer.nric;
        this.customer.email = userRawProfile
          ? userRawProfile.email
          : customer.email;
        this.customer.detail.city = userRawProfile
          ? userRawProfile.city
          : customer.city
          ? customer.city
          : customer.detail?.city;
        this.customer.detail.state = userRawProfile
          ? userRawProfile.state
          : customer.state
          ? customer.state
          : customer.detail?.state;

        if (this.store.getters.getCustomerData.user_raws.length > 0) {
          for (let index of Object.keys(
            this.store.getters.getCustomerData.user_raws
          )) {
            if (
              this.store.getters.getCustomerData.user_raws[index].type == "PFP"
            ) {
              this.customerPFP = this.store.getters.getCustomerData.user_raws[
                index
              ];
            }
          }

          this.isLoading = false;
        } else {
          if (
            this.store.getters.getCustomerData.detail?.employment_status ==
            "Unemployed"
          ) {
            this.customerPFP.raw.profile = "PFP A";
            this.customerPFP.raw.is_join_pfp = "Yes";
          } else {
            this.customerPFP.raw.profile = "PFP B";
            this.customerPFP.raw.is_join_pfp = "No";
          }
          this.storePFPProfiling(this.customerPFP);
        }

        this.store.dispatch(Actions.GET_PFPS, this.cstmr_id).then(() => {
          let pfps = this.store.getters.getPfpsData;

          for (let index = 0; index < pfps.length; index++) {
            if (pfps[index].status == "PFP Report Generated") {
              this.pfpReportGenerated = true;
            }
          }

          if (
            (this.customerPFP.raw.profile == "PFP A" &&
              this.customerPFP.raw.is_join_pfp == "Yes") ||
            (this.customerPFP.raw.profile == "PFP B" &&
              this.customerPFP.raw.is_join_pfp != "Withdraw")
          ) {
            if (!this.pfpReportGenerated) {
              this.showGeneratePFPReportBttn = true;
            }
          }
        });
      });
    },
    storePFPProfiling(pfp) {
      this.store.dispatch(Actions.STORE_CUSTOMER_RAW, pfp).then(() => {
        this.isLoading = false;
      });
    },
    updatePFPProfiling(pfp) {
      this.store.dispatch(Actions.UPDATE_CUSTOMER_RAW, pfp).then(() => {
        Swal.fire({
          text: "PFP Profiling has been updated",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
      });
    },
    updateShowOqfForm(show) {
      this.showOqfForm = show;
    },
    updateDMPProfiling(dmpProfile) {
      this.customer.detail.dmp_profiling = dmpProfile;
    },
    handleCommandOqf(command) {
      this.store.dispatch(Actions.GET_OQFS, this.cstmr_id).then(() => {
        this.oqfs = this.store.getters.getOqfsData;

        if (this.oqfs && this.oqfs.length > 0) {
          let lastSubmittedDate = this.oqfs[0].created_at;
          this.lastSubmittedOqf = this.getHumanDate(lastSubmittedDate);
          let today = moment();
          var diff = moment(today).diff(lastSubmittedDate, "days");
          if (diff >= 5) {
            this.showOqfForm = true;
            this.showInOqfForm = true;
          }
        } else {
          this.showOqfForm = true;
          this.showInOqfForm = true;
        }
      });

      if (command == "increase-instalment" || command == "Postponement") {
        this.oqfReason = command;
      } else if (command == "withdraw-dmp") {
        this.oqfReason = this.oqfWithdrawalDMPReason;
      } else if (command == "withdraw-fi") {
        this.oqfReason = this.oqfWithdrawalFIReason;
      }

      const oqfData = {
        customer_name: this.customer.name,
        user_id: this.cstmr_id,
        contact_number: this.customer.phone,
        email: this.customer.email,
        remarks: "-",
        reason: this.oqfReason,
        created_by: this.store.getters.currentUser.id,
      };

      if (command == "withdraw-dmp" || command == "withdraw-fi") {
        this.store.dispatch(Actions.STORE_OQF, oqfData).then(() => {
          this.showOqfForm = false;
          this.showInOqfForm = false;
          window.open(
            "https://customer.akpk.org.my/customerindex.html#/AccountCustomer/CustomerLogin"
          );
          this.$router.go(0);
        });
      }
    },
    withdrawPFP(remarks) {
      this.customerPFP.raw.remarks = remarks;
      this.customerPFP.raw.is_join_pfp = "Withdraw";
      this.updatePFPProfiling(this.customerPFP);
    },
    joinPFP() {
      let message = "Are you sure want to join PFP?";
      let title = "Join PFP!";
      if (this.customerPFP.raw.remarks) {
        title = "Rejoin PFP!";
        message = "Are you sure want to rejoin PFP?";
      }

      Swal.fire({
        title: title,
        text: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, I am not!",
        confirmButtonText: "OK",
      }).then((value) => {
        if (value.isConfirmed) {
          this.customerPFP.raw.is_join_pfp = "Yes";
          if (this.customerPFP.raw.remarks) {
            this.customerPFP.raw.remarks = "Rejoin PFP";
          }
          this.updatePFPProfiling(this.customerPFP);
        }
      });
    },
    getHumanDate(date) {
      return moment(date).format("DD/MM/YYYY h:mm:ss a");
    },
  },
  async mounted() {
    const pathArray = this.$route.path.split("/");
    let currPath = pathArray[pathArray.length - 1];
    let detailsPath = pathArray[4];

    console.log(detailsPath);

    if (detailsPath == "financial-planning") {
      this.isViewDetails = false;
    }

    this.init();
  },
});
