import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "col-4 my-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.certReady)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (!this.url)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.generateCertificate && _ctx.generateCertificate(...args))),
                  class: "mt-5 btn btn-primary btn-sm"
                }, " Generate Certificate "))
              : _createCommentVNode("", true),
            (this.url)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  type: "button",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.viewCertificate && _ctx.viewCertificate(...args))),
                  class: "mt-5 btn btn-primary btn-sm"
                }, " View Certicate "))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}