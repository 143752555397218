
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "DigitalFeedback",
  props: ["cstmr_id"],
  components: {
    Field,
    Form,
  },
  data() {
    const store = useStore();
    const isCustomerFeedbackReady = false;

    const feedback = {
      how_do_you_know: {
        bank: false,
        exhibition: false,
        media: false,
        talk: false,
        friend: false,
        other: false,
      },
      reason_rejected: false,
      financial_standing: false,
      financial_knowledge: false,
      financial_goal: false,
      financial_planning: false,
    };

    return {
      store,
      feedback,
      isCustomerFeedbackReady
    };
  },
  setup() {
    return {};
  },
  methods: {
    init() {
      this.store
        .dispatch(Actions.GET_USER_RAW, {
          user_id: this.cstmr_id,
          type: "myknp_customer_feedback",
        })
        .then(() => {
          const data = this.store.getters.getMyKNPCustomerFeedbackData;
          if (data.feedback) {
            this.feedback = data.feedback;
            this.onUpdateCustomerFeedbackInfo();
          }
          this.isCustomerFeedbackReady = true;
        });
    },
    onSubmitCustomerFeedbackForm(values) {
      Swal.fire({
        text: "Are you sure to save the Customer Feedback?",
        icon: "info",
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            user_id: this.cstmr_id,
            type: "myknp_customer_feedback",
            raw: {
              feedback: this.feedback,
            },
          };
          this.store.dispatch(Actions.STORE_RAW, data).then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "MyKNP Pre Assessment Info saved!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            });
            this.onUpdateCustomerFeedbackInfo();
          });
        }
      });
    },
    onUpdateCustomerFeedbackInfo() {
      this.$emit("updateSavedInfo", "customerFeedback", true);
    },
  },
  async mounted() {
    this.init();
  },
});
