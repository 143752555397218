
import { defineComponent, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";

import SuccessCheck from "../../../../public/media/icons/SuccessCheck.vue";

export default defineComponent({
  name: "cmyknp-checlist-item",
  props: ["checklistName", "checklistValue","checklistSubmitted"],
  components: {
    Field,
    // ErrorMessage,
    SuccessCheck,
  },
  data() {
    return {
      checklist: this.checklistValue,
    };
  },
  methods: {
    onCheck(e) {
      this.$emit("onCheck", e.target.checked, this.checklistName);
    },

    updateCheck(value) {
      this.checklist = value;
    },
  },
})

