import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "svg-icon svg-icon-success svg-icon-2hx"
}
const _hoisted_2 = {
  key: 1,
  class: "form-check form-switch form-check-custom form-check-solid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuccessCheck = _resolveComponent("SuccessCheck")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.checklistSubmitted)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_component_SuccessCheck)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.checklistSubmitted)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createVNode(_component_Field, {
            modelValue: _ctx.checklist,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checklist) = $event)),
            onChange: _ctx.onCheck,
            type: "checkbox",
            value: true,
            class: "form-check-input"
          }, null, 8, ["modelValue", "onChange"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}