
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import _ from "lodash";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Modal } from "bootstrap";
import * as Yup from "yup";

export default defineComponent({
  name: "Documents",
  props: ["cstmr_id"],
  components: {
    // ErrorMessage,
    // Field,
    // Form,
  },
  data() {
    const media = [];
    const user_id = this.cstmr_id;
    const baseURL = process.env.VUE_APP_DOC_URL;

    return {
      myknpFDS: null,
      documents: new FormData(),
      store: useStore(),
      media,
      user_id,
      myKNPCert: null,
      baseURL,
      url: null,
      certReady: false,
    };
  },
  methods: {
    init() {
      this.store
        .dispatch(Actions.GET_MYKNP_CERTIFICATE, this.user_id)
        .then(() => {
          this.myKNPCert = this.store.getters.getMyKNPCertificateData.data;
          if(this.myKNPCert?.url) {
            this.url = this.baseURL + "/" + this.myKNPCert?.url;
          }
          this.certReady = true;
        });
    },
    generateCertificate() {
      Swal.fire({
        title: "Generating Certificate",
        text:
          "We are generating your certificate. This may take a while. Please wait.",
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });

      this.store
        .dispatch(Actions.STORE_MYKNP_CERTIFICATE, {
          user_id: this.user_id,
        })
        .then(() => {
          this.store
            .dispatch(Actions.GET_MYKNP_CERTIFICATE, this.user_id)
            .then(() => {
              this.myKNPCert = this.store.getters.getMyKNPCertificateData.data;

              let url = this.baseURL + "/" + this.myKNPCert.url;

              Swal.fire({
                title: "Certificate is successfully generated.",
                icon: "success",
                heightAuto: false,
                allowOutsideClick: false,
                confirmButtonText: "View Certificate Now",
              }).then(() => {
                window.open(url, "_blank");
                window.location.reload();
              });
            });
        });
    },
    viewCertificate() {
      window.open(this.url, "_blank");
      window.location.reload();
    },
  },
  async mounted() {
    this.init();
  },
});
