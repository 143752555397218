
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";

import MyKNPCheclist from "./Checklist.vue";
import PreAssessemnt from "./PreAssessment.vue";
import CustomerFeedback from "./CustomerFeedback.vue";
import Documents from "./Documents.vue";
import Certificate from "./Certificate.vue";

export default defineComponent({
  name: "customer-dmp-plan",
  props: ["cstmr_id"],
  components: {
    MyKNPCheclist,
    PreAssessemnt,
    CustomerFeedback,
    Documents,
  },
  data() {
    const checlistSaved = false;
    const preAssessmentSaved = false;
    const customerFeedbackSaved = false;
    const propertySaved = false;
    const documentsSaved = false;
    return {
      checlistSaved,
      preAssessmentSaved,
      customerFeedbackSaved,
      propertySaved,
      documentsSaved,
    };
  },
  methods: {
    init() {
      // console.log(this.checlistSaved, this.preAssessmentSaved, this.customerFeedbackSaved, this.propertySaved);
    },
    updateSavedInfo(info, isSaved) {
      if (info == "checklist") {
        this.checlistSaved = isSaved;
      } else if (info == "property") {
        this.propertySaved = isSaved;
      } else if (info == "preAssessment") {
        this.preAssessmentSaved = isSaved;
      } else if (info == "customerFeedback") {
        this.customerFeedbackSaved = isSaved;
      } else if (info == "documents") {
        this.documentsSaved = isSaved;
      }
      console.log(
        this.checlistSaved,
        this.preAssessmentSaved,
        this.customerFeedbackSaved,
        this.propertySaved,
        this.documentsSaved
      );
    },
  },
  async mounted() {
    this.init();
  },
});
