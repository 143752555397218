
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import * as Yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ElLoading } from "element-plus";
// import upload from "element-plus/es/components/upload/src/ajax";
// import { Action } from "vuex-module-decorators";
// import { ElLoading } from "element-plus";

export default defineComponent({
  name: "joint-holder",
  props: ["joinCustomers"],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const formJointCustomerValidator = Yup.object({
      jointCustomer: Yup.object({
        name: Yup.string().required().label("Name"),
        nric: Yup.string()
          .test("len", "Must be exactly 12 digits", (val) => val?.length === 12)
          .required()
          .label("NRIC"),
      }),
    });

    return {
      formJointCustomerValidator,
    };
  },
  data() {
    const addedJoinCustomers = this.joinCustomers;

    const jointCustomer = {
      name: "",
      nric: "",
    };

    return {
      addedJoinCustomers,
      jointCustomer,
    };
  },
  methods: {
    init() {
      console.log(this.joinCustomers);
    },

    onAddJointCustomer(value, { resetForm }) {
      this.addedJoinCustomers.push({
        name:this.jointCustomer.name,
        nric:this.jointCustomer.nric
      });
      this.onUpdateJoinCustomer();
      resetForm();

    },
    delJointCustomer(index) {
      this.addedJoinCustomers.splice(index,1);
      this.onUpdateJoinCustomer();
    },
    onUpdateJoinCustomer(){
      this.$emit('updateJoinCustomers',this.addedJoinCustomers)
    }
  },
  async mounted() {
    this.init();
  },
});
